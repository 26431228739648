<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Fundamentals of a Combustible Dust Safety Program"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course addresses the NFPA 652 requirements for dust testing and
            the associated Combustible Dust Hazards Analysis (DHA). The course
            will begin with a review of combustible dust heritage and the
            development of the current industry standards as they relate to the
            current NFPA 652 regulation. The course will then review methods and
            protocols for hazard identification, including dust characterization
            testing and data analysis for risk reduction. The second portion of
            this course will focus on how to organize, lead, and implement the
            DHA study. The course will address the tactics and success factors
            that help ensure a successful study. Participants will work in
            groups to apply DHA methodologies to example combustible dust
            scenarios. Instructors will work closely with the participants
            throughout this session/workshop to ensure that key principles are
            understood. By applying the DHA methodologies to practical example
            processes, the participants will internalize the principles
            presented and gain added insight to the value of performing thorough
            and accurate DHAs at their facilities. The combustible dust course
            will provide managers, engineers, safety professionals, and others
            involved in combustible dust programs with an understanding of the
            practices and procedures that are required to ensure safe operation
            and regulatory compliance.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Combustible dust heritage</li>
            <li>Fundamentals of dust explosions</li>
            <li>NFPA 652 background and program development</li>
            <li>Regulatory review (OSHA PSM, NFPA, NEC)</li>
            <li>Material screening</li>
            <li>Material characterization</li>
            <li>Application of test data</li>
            <li>Responsibilities</li>
            <li>
              Fundamental combustible dust program
              <ul>
                <li>Hazard identification</li>
                <li>Design options</li>
                <li>Dust Hazard Analysis (DHA)</li>
                <li>Hazard management</li>
                <li>Management systems</li>
              </ul>
            </li>
            <li>Preparing and organizing DHA studies</li>
            <li>Determining appropriate DHA methodology</li>
            <li>Leadership skill for managing the team</li>
            <li>Design intent, parameters and deviations</li>
            <li>Human factors</li>
            <li>Application of dust test data</li>
            <li>Documentation</li>
            <li>Implementation of recommendations</li>
            <li>Managing the follow-up DHA results</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Combustible Dust Safety Course",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course addresses the NFPA 652 requirements for dust testing and the associated Combustible Dust Hazards Analysis (DHA). The course will begin with a review of combustible dust heritage and the development of the current industry standards as they relate to the current NFPA 652 regulation."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
